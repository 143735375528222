<template>
  <!-- 
    Used to draw areas on a map that will be used by the eventMovies
   -->
  <Portlet
    :title="$t('eventMovie')"
    icon="video"
    class="eventMovie"
  >
    <template slot="buttons">
      <toggle-button
        v-model="baseLayerBool"
        :labels="{ checked: $t('map'), unchecked: $t('satellite') }"
        :color="{ checked: 'gray', unchecked: 'green' }"
        :width="80"
        :height="30"
        :font-size="10"
        class="m-0"
        @change="changeBaseLayer()"
      />
    </template>

    <div class="row">
      <div class="col-12 col-xl-6 mb-3">
        <div id="eventMovieWrapper" />

        <div class="input-group">
          <input
            v-model="searchText"
            :class="['form-control rounded-0', { 'is-invalid': errors.has(`text`) }]"
            :placeholder="$t('eventMovieComp.searchLocation')"
            type="text"
            @keyup.enter="findLocation()"
          >
          <div class="input-group-append">
            <button
              class="btn btn-primary rounded-0"
              @click="findLocation()"
            >
              <font-awesome-icon
                class="mr-2"
                icon="search"
              />
              <span>{{ $t('search') }}</span>
            </button>
          </div>
        </div>
        <span
          v-show="errors.has(`text`)"
          class="badge badge-danger"
        >{{ errors.first(`text`) }}</span>

        <p class="mt-3">
          {{ $t('eventMovieComp.instructions') }}
        </p>
        <p>{{ $t('eventMovieComp.geoInstructions') }}</p>
      </div>
      <div class="col-12 col-xl-6 mb-3">
        <div :key="mapIndex">
          <table class="areaNames defaultTable">
            <colgroup>
              <col width="50">
              <col>
              <col>
            </colgroup>

            <thead>
              <tr>
                <th
                  class="p-3"
                  colspan="2"
                >
                  {{ $t('area') }}
                </th>
                <th>{{ $t('eventMovieComp.receiver') }}</th>
                <th>{{ $t('eventMovieComp.event') }}</th>
                <th>{{ $t('eventMovieComp.command') }}</th>
                <th class="p-2 min">
                  <button
                    class="btn btn-sm btn-primary btn-block"
                    @click="addEntry()"
                  >
                    <font-awesome-icon
                      class="mr-2"
                      icon="plus"
                    />
                    <span>{{ $t('add') }}</span>
                  </button>
                </th>
              </tr>
            </thead>

            <tbody>
              <tr
                v-for="(editableLayer, editableLayerIndex) in orderedLayers"
                :key="`eventMovieEditableLayer-${ editableLayerIndex }`"
              >
                <td>
                  <span
                    :style="`background-color: ${ editableLayer.options.color ? editableLayer.options.color : 'gray' }; color: white;`"
                    class="badge badge-pill cursor-pointer"
                    @click="moveToArea(editableLayer)"
                  >{{ editableLayer.id }}
                  </span>
                </td>
                <td>{{ editableLayer.name }}</td>
                <td>{{ editableLayer.receiver }}</td>
                <td>{{ editableLayer.event }}</td>
                <td>{{ editableLayer.command }}</td>
                <td class="min">
                  <div class="btn-group">
                    <template v-if="editableLayer.isActive == true">
                      <button
                        v-if="editableLayer != null && editableLayer.drawn == true"
                        class="btn btn-sm btn-primary"
                        @click="finishEditPolygon(editableLayer.id)"
                      >
                        <font-awesome-icon
                          class="mr-1"
                          icon="check"
                        />
                        <span>
                          {{ $t('save') }}
                        </span>
                      </button>
                      <button
                        class="btn btn-sm btn-danger"
                        @click="discardEditPolygon(editableLayer.id)"
                      >
                        <font-awesome-icon
                          class="mr-2"
                          icon="times"
                        />
                        <span>{{ $t('cancel') }}</span>
                      </button>
                    </template>
                    <template v-else>
                      <button
                        class="btn btn-sm btn-primary"
                        @click="addPolygon(editableLayer.id)"
                      >
                        <font-awesome-icon
                          class="mr-2"
                          icon="edit"
                        />
                        <span>{{ $t('draw') }}</span>
                      </button>
                      <button
                        class="btn btn-sm btn-secondary"
                        @click="enableSidebar(editableLayer.id)"
                      >
                        <font-awesome-icon
                          class="mr-2 gray"
                          icon="eye"
                          style="vertical-align: middle;"
                        />
                        {{ $t('edit') }}
                      </button>
                      <button
                        class="btn btn-sm btn-secondary"
                        @click="removePolygon(editableLayer.id)"
                      >
                        <font-awesome-icon
                          class="mr-2 gray"
                          icon="trash"
                        />
                        <span>{{ $t('remove') }}</span>
                      </button>
                    </template>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          
          <div
            v-if="unsavedChanges == true"
            class="alert alert-warning mt-3 mb-0"
            role="alert"
          >
            <font-awesome-icon
              class="mr-2"
              icon="exclamation-triangle"
            />
            {{ $t('unsavedChanges') }}
          </div>
        </div>

        <button
          class="btn btn-primary mt-3 float-right"
          @click="savePolygons()"
        >
          <font-awesome-icon
            class="mr-1"
            icon="upload"
          />
          <span>
            {{ $t('save') }}
          </span>
        </button>
        <div class="clearfix" />
      </div>
    </div>

    <Sidebar
      v-if="currentId != null"
      :show-sidebar="showSidebar"
      @close="disableSidebar(false)"
    >
      <label>{{ $t('name') }}</label>
      <input
        v-model="nameToEdit"
        :placeholder="$t('pleaseEnterText')"
        class="form-control mb-3"
        maxlength="100"
        type="text"
      >

      <label>{{ $t('eventMovieComp.receiver') }}</label>
      <input
        v-model="receiverToEdit"
        :placeholder="$t('pleaseEnterText')"
        class="form-control mb-3"
        maxlength="100"
        type="text"
      >

      <label>{{ $t('eventMovieComp.deviceKey') }}</label>
      <input
        v-model="deviceKeyToEdit"
        :placeholder="$t('pleaseEnterText')"
        class="form-control mb-3"
        maxlength="100"
        type="text"
      >

      <label>{{ $t('eventMovieComp.event') }}</label>
      <select
        v-model="eventToEdit"
        class="form-control mb-3"
      >
        <option
          :value="null"
          selected
        >
          {{ $t('pleaseSelectOne') }}
        </option>
        <option value="Arrive">
          {{ $t('eventMovieComp.arrive') }}
        </option>
        <option value="Leave">
          {{ $t('eventMovieComp.leave') }}
        </option>
      </select>

      <label>{{ $t('eventMovieComp.command') }}</label>
      <input
        v-model="commandToEdit"
        :placeholder="$t('pleaseEnterText')"
        class="form-control mb-3"
        maxlength="100"
        type="text"
      >
            
      <table
        v-if="currentLayer != null && currentLayer.drawn == true"
        class="defaultTable"
      >
        <colgroup>
          <col width="60">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('index') }}</th>
            <th>{{ $t('latitude') }}</th>
            <th>{{ $t('longitude') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(point, index) in currentLayer.editing.latlngs[0][0]"
            :key="`eventMoviePointTableRow-${ index }`"
          >
            <td>{{ index }}</td>
            <td>
              <input
                v-model.number="point.lat"
                type="number"
                step="0.00001"
                class="form-control"
                @input="updateLayer($event, index, 'lat')"
              >
            </td>
            <td>
              <input
                v-model.number="point.lng"
                type="number"
                step="0.00001"
                class="form-control"
                @input="updateLayer($event, index, 'lng')"
              >
            </td>
          </tr>
        </tbody>
      </table>
      <button
        class="btn btn-sm btn-danger float-left mt-3"
        @click="disableSidebar(false)"
      >
        <font-awesome-icon
          class="mr-2"
          icon="times"
        />
        <span>{{ $t('cancel') }}</span>
      </button>
      <button
        v-if="nameToEdit.length > 0"
        class="btn btn-sm btn-primary float-right mt-3"
        @click="disableSidebar(true)"
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>
          {{ $t('done') }}
        </span>
      </button>
      <button
        v-else
        class="btn btn-sm btn-primary float-right mt-3"
        disabled
      >
        <font-awesome-icon
          class="mr-1"
          icon="check"
        />
        <span>
          {{ $t('done') }}
        </span>
      </button>
      <div class="clearfix" />
    </Sidebar>
  </Portlet>
</template>

<script>
import _ from 'lodash';
import { errorMixin } from '@/mixins/errorMixin.js';
import L from 'leaflet';
import 'leaflet-draw';

(function () {
  var originalOnTouch = L.Draw.Polyline.prototype._onTouch;
  L.Draw.Polyline.prototype._onTouch = function ( e ) {
    if ( e.originalEvent.pointerType != 'mouse' ) {
      return originalOnTouch.call(this, e);
    }
  }
})();


export default {
  name: 'EventMovie',
  components: {
    Sidebar: () => import('@/components/Base/Sidebar.vue')
  },
  mixins: [
    errorMixin
  ],
  props: {
    installationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      locationMarker: null,
      unsavedChanges: false,
      drawingId: 0,
      nameToEdit: '',
      eventToEdit: '',
      commandToEdit: '',
      receiverToEdit: '',
      deviceKeyToEdit: '',
      nameToUse: 'New Area',
      polygonPosition: null,
      drawingPolygon: false,
      polygonDrawing: null,
      baseLayers: null,
      baseLayerBool: true,
      currentId: null,
      showSidebar: false,
      orderedLayers: null,
      colors: [
        'red',
        'blue',
        'green',
        'firebrick',
        'black',
        'aqua',
        'cadetblue',
        'chartreuse',
        'coral',
        'cornflowerblue',
        'crimson',
        'darkorange',
        'deeppink',
        'brown',
        'pink',
        'darkblue',
        'darkgreen',
        'lightblue'
      ],
      layerLength: 0,
      searchText: '',
      geoCoder: null,
      mapAttrib: '&copy; <a href="http://www.esri.com/">Esri</a>',
      mapUrl: 'http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
      wikiAttrib: '&copy; <a href="https://wikimediafoundation.org/wiki/Maps_Terms_of_Use">Wikimedia</a>',
      wikiUrl: 'https://a.tile.openstreetmap.org/${z}/${x}/${y}.png',
      contributionUrl: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      mapIndex: 0,
      defaultCenter: [47, 9],
      initialised: false,
      drawPluginOptions: {
        position: 'topright',
        draw: {
            polygon: {
              allowIntersection: false,
              drawError: {
                color: '#e1e100',
                message: '<strong>Oh snap!<strong> you can\'t draw that!'
              },
              shapeOptions: {
                color: '#97009c'
              },
              remove: true
            },
            polyline: false,
            circle: false,
            circlemarker: false,
            rectangle: false,
            marker: false
          },
        edit: {
          featureGroup: null,
          remove: true
        }
      },
      drawControl: null,
      editableLayers: null,
      map: null,
      attribution: this.wikiAttrib,
      zoom: 6,
      maxZoom: 18,
      url: this.wikiUrl,
      polygonData: null
    }
  },
  computed: {
    currentLayer () {
      let temporaryLayers = this.editableLayers.getLayers();
      let temporaryLayer = temporaryLayers.find(layer => {
        return Number(layer.id) === Number(this.currentId);
      });
      return temporaryLayer;
    },
    bounds () {
      let corner1 = null;
      let corner2 = null;
      if (this.polygonData != null) {
        corner1 = {latitude: 0, longitude: 0};
        corner2 = {latitude: 0, longitude: 0};
        this.polygonData.forEach((area, areaIndex) => {
          area.positions.forEach((position, positionIndex) => {
            if (position.latitude == null) {
              return;
            }
            if (areaIndex == 0 && positionIndex == 0) {
              corner1.latitude = position.latitude;
              corner1.longitude = position.longitude;
              corner2.latitude = position.latitude;
              corner2.longitude = position.longitude;
            } else {
              if (position.latitude < corner1.latitude) {
                corner1.latitude = position.latitude;
              }
              if (position.longitude < corner1.longitude) {
                corner1.longitude = position.longitude;
              }
              if (position.latitude > corner2.latitude) {
                corner2.latitude = position.latitude;
              }
              if (position.longitude > corner2.longitude) {
                corner2.longitude = position.longitude;
              }
            }
          });
        });
        corner1 = L.latLng(corner1.latitude, corner1.longitude);
        corner2 = L.latLng(corner2.latitude, corner2.longitude);
      } else {
        corner1 = L.latLng(48.980216985374994, 17.314453125000004);
        corner2 = L.latLng(46.55886030311719, 9.931640625000002);
      }
      let bounds = L.latLngBounds(corner1, corner2);
      return bounds;
    }
  },
  mounted () {
    this.wikiUrl = 'https://a.tile.openstreetmap.org/{z}/{x}/{y}.png';
    this.getEventMovieArea();
  },
  beforeDestroy () {
    if (this.map != null) {
      this.map.remove();
    }
  },
  methods: {
    addEntry () {
      if (this.polygonData == null) {
        this.polygonData = [];
      }
      let uniqueInt = this.getUniqueIndex();
      this.polygonData.push({
        id: uniqueInt,
        name: this.$t('eventMovieComp.newArea'),
        event: "",
        command: "",
        deviceKey: "",
        receiver: "",
        drawn: false,
        positions: [
          {
            latitude: null,
            longitude: null
          }
        ],
        options: {
          color: this.colors[uniqueInt]
        }
      });
      this.orderLayers();
      this.layerLength++;
      this.increaseIndex();
      this.unsavedChanges = true;
    },
    moveToArea (area) {
      let editableLayer = null;
      this.editableLayers.eachLayer((o) => {
        if (Number(o.id) === Number(area.id)) {
          editableLayer = o;
        }
      });
      if (editableLayer == null) {
        this.$snotify.warning(this.$t('eventMovieComp.noCoordinatesMessage'), this.$t('eventMovieComp.noCoordinatesTitle'));
        return;
      }
      let bounds = editableLayer.getBounds();
      this.map.fitBounds(bounds);
    },
    changeBaseLayer () {
      if (this.baseLayerBool == true) {
        this.map.removeLayer(this.baseLayers['Satellite']);
        this.map.addLayer(this.baseLayers['Map']);
      } else {
        this.map.removeLayer(this.baseLayers['Map']);
        this.map.addLayer(this.baseLayers['Satellite']);
      }
    },
    updateLayer (event, index, variableName) {
      if (!isNaN(event.target.value)) {
        this.currentLayer.editing.latlngs[0][0][index][variableName] = Number(event.target.value).toFixed(5);
      } else if (this.currentLayer.editing.latlngs[0][0][index][variableName] != null) {
        this.currentLayer.editing.latlngs[0][0][index][variableName] = 0;
      }

      let temporaryLayer = this.editableLayers.getLayers().find(o => {
        return Number(o.id) === Number(this.currentId);
      });
      this.currentLayer.redraw();
      temporaryLayer.editing.disable();
      temporaryLayer.editing.enable();
      // this.unsavedChanges = true;
    },
    enableSidebar (id) {
      let tmpPolygon = this.polygonData.find((o) => {
        return Number(o.id) === Number(id);
      });
      let temporaryLayer = this.editableLayers.getLayers().find(o => {
        return Number(o.id) === Number(id);
      });
      this.nameToEdit = tmpPolygon.name;
      this.eventToEdit = tmpPolygon.event ? tmpPolygon.event : null;
      this.commandToEdit = tmpPolygon.command;
      this.deviceKeyToEdit = tmpPolygon.deviceKey;
      this.receiverToEdit = tmpPolygon.receiver;
      this.currentId = id;
      if (temporaryLayer != null) {
        this.editPolygon(id);
      }
      this.showSidebar = true;
    },
    disableSidebar (savePoly) {
      if (savePoly == true) {
        this.renamePolygon(this.currentId);
        this.finishEditPolygon(this.currentId);
      } else {
        this.discardEditPolygon(this.currentId);
      }
      this.nameToEdit = '';
      this.eventToEdit = '';
      this.commandToEdit = '';
      this.deviceKeyToEdit = '';
      this.receiverToEdit = '';
      this.showSidebar = false;
    },
    finishEditPolygon (id) {
      let tmpPolygon = this.polygonData.find(o => Number(o.id) === Number(id));
      tmpPolygon.isActive = false;
      if (tmpPolygon.drawn == true) {
        let temporaryLayer = this.editableLayers.getLayers().find(o => {
          return Number(o.id) === Number(id);
        });
        temporaryLayer.isActive = false;
        if (temporaryLayer != null && temporaryLayer.editing != null) {
          temporaryLayer.editing.disable();
        }
        tmpPolygon.positions = temporaryLayer.editing.latlngs[0][0].map((o) => {
          return { latitude: o.lat, longitude: o.lng }
        });
      }
      this.currentId = null;
      this.increaseIndex();
      this.unsavedChanges = true;
    },
    editPolygon (id) {
      this.currentId = id;
      let tmpPolygon = this.polygonData.find(o => Number(o.id) === Number(id));
      let temporaryLayer = this.editableLayers.getLayers().find(o => {
        return Number(o.id) === Number(id);
      });
      this.polygonPosition = _.cloneDeep(temporaryLayer.editing.latlngs);
      temporaryLayer.isActive = true;
      tmpPolygon.isActive = true;
      temporaryLayer.editing.enable();
      this.increaseIndex();
    },
    discardEditPolygon (id) {
      let tmpPolygon = this.polygonData.find(o => Number(o.id) === Number(id));
      if (tmpPolygon.positions[0].latitude == null) {
        this.cancelDrawingPolygon(id);
        return;
      } else {
        let temporaryLayer = this.editableLayers.getLayers().find(o => {
          return Number(o.id) === Number(id);
        });
        temporaryLayer.editing.latlngs = this.polygonPosition;
        temporaryLayer._latlngs = this.polygonPosition;
        this.currentLayer.redraw();
        tmpPolygon.isActive = false;
        if (tmpPolygon.drawn == true) {
          temporaryLayer.isActive = false;
          if (temporaryLayer != null && temporaryLayer.editing != null) {
            temporaryLayer.editing.disable();
          }
        }
      }
      this.orderLayers();
    },
    orderLayers () {
      this.orderedLayers = Object.keys(this.polygonData).map((key) => {
        return this.polygonData[key];
      });
      this.orderedLayers.sort((a, b) => {
        if (Number(a.id) < Number(b.id)) {
          return -1;
        }
        if (Number(a.id) > Number(b.id)) {
          return 1;
        }
        return 0;
      });
      return this.orderedLayers;
    },
    findLocation () {
      this.axios.get(`/Location/GetByName?text=${ this.searchText }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.status == null) {
            return;
          }
          if (response.status == 204) {
            this.$snotify.error(this.$t('eventMovieComp.locationNotFoundMessage'), this.$t('eventMovieComp.locationNotFoundTitle'));
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.latitude == null || response.data.longitude == null) {
            return;
          }
          this.error_clear();
          this.map.panTo(L.latLng(response.data.latitude, response.data.longitude));
          if (this.locationMarker != null) {
            this.map.removeLayer(this.locationMarker)
          }
          this.locationMarker = L.marker([response.data.latitude, response.data.longitude]).addTo(this.map);
        })
        .catch((error) => {
          if (error.status == null) {
            return;
          }
          this.error_clear();
          this.error_validate(error);
        });
    },
    renamePolygon (index) {
      let temporaryLayer = this.editableLayers.getLayers().find(o => {
        return Number(o.id) === Number(index);
      });
      this.polygonData[index].name = this.nameToEdit;
      this.polygonData[index].event = this.eventToEdit;
      this.polygonData[index].command = this.commandToEdit;
      this.polygonData[index].deviceKey = this.deviceKeyToEdit;
      this.polygonData[index].receiver = this.receiverToEdit;
      if (temporaryLayer != null && temporaryLayer != undefined) {
        temporaryLayer.name = this.nameToEdit;
        temporaryLayer.event = this.eventToEdit;
        temporaryLayer.command = this.commandToEdit;
        temporaryLayer.deviceKey = this.deviceKeyToEdit;
        temporaryLayer.receiver = this.receiverToEdit;
        temporaryLayer.setTooltipContent(`${ temporaryLayer.name } (${ index })`);
      }
      this.increaseIndex();
      this.unsavedChanges = true;
    },
    removePolygon (id) {
      this.currentId = null;
      this.editableLayers.removeLayer(this.editableLayers.getLayers().find(o => {
        return Number(o.id) === Number(id);
      }));
      let index = this.polygonData.map(function (area) {
        return area.id;
      }).indexOf(id);
      this.polygonData.splice(index, 1);
      this.orderLayers();
      this.layerLength--;
      this.increaseIndex();
      this.unsavedChanges = true;
    },
    addPolygon (id) {
      let tmpPolygon = this.polygonData.find(o => Number(o.id) === Number(id));
      tmpPolygon.isActive = true;
      this.increaseIndex();
      let temporaryLayers = this.editableLayers.getLayers();
      let temporaryLayer = temporaryLayers.find(layer => {
        return Number(layer.id) === Number(id);
      });
      if (temporaryLayer != null && temporaryLayer != undefined) {
        this.editPolygon(id);
        return;
      } else {
        this.polygonDrawing = new L.Draw.Polygon(this.map, this.drawControl.options.draw.polygon);
        this.drawingId = id;
        this.polygonDrawing.enable();
        this.drawingPolygon = true;
      }
    },
    cancelDrawingPolygon (id) {
      let tmpPolygon = this.polygonData.find(o => Number(o.id) === Number(id));
      tmpPolygon.isActive = false;
      if (this.polygonDrawing != null) {
        this.polygonDrawing.disable();
      }
      this.drawingPolygon = false;
      this.increaseIndex();
      this.unsavedChanges = false;
    },
    getEventMovieArea () {
      this.axios.get(`/Config/GetPositionTrackerConfig?installationId=${ this.installationId }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.areas == null) {
            return;
          }
          if (response.data.areas.length == 0) {
            return;
          }
          this.polygonData = response.data.areas;
        })
        .finally(() => {
          if (this.initialised == false) {
            this.initialised = true;
            setTimeout(() => {
              this.initMap();
            }, 25);
          }
        });
    },
    savePolygons () {
      let positiontrackerConfig = {
        areas: []
      };
      this.orderedLayers.forEach((o) => {
        positiontrackerConfig.areas.push({
          id: o.id,
          name: o.name,
          positions: o.positions,
          deviceKey: o.deviceKey,
          event: o.event,
          receiver: o.receiver,
          command: o.command,
          drawn: o.drawn
        });
      });
      this.axios.post(`/Config/UpdatePositionTrackerConfig?installationId=${ this.installationId }`, positiontrackerConfig)
        .then((response) => {
          if (response.status == 200) {
            this.error_clear();
            this.$snotify.success(this.$t('eventMovieComp.eventMovieAreaUpdated'));
            this.unsavedChanges = false;
          }
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
          if (this.errors.has(`positiontrackerConfig`)) {
            this.$snotify.error(this.$t('eventMovieComp.noValidPolygonsToSaveMessage'), this.$t('eventMovieComp.noValidPolygonsToSaveTitle'));
          }
        });
    },
    initMap () {
      if (this.map != null) {
        this.map.remove();
      }
      document.getElementById('eventMovieWrapper').innerHTML = "<div id='map' style='width: 100%; height: 100%; min-height: 500px;'></div>";

      let wikiMap = L.tileLayer(this.wikiUrl, {attribution: this.contributionUrl});
      let landMap = L.tileLayer(this.mapUrl, {attribution: this.mapAttrib});
      // create the map and save it
      this.map = L.map('map', { layers: [wikiMap], zoomDelta: 0.5, zoomSnap: 0 });
      this.baseLayers = {};
      this.baseLayers['Satellite'] = landMap;
      this.baseLayers['Map'] = wikiMap;

      // create the layer that will store the polygons
      this.editableLayers = new L.FeatureGroup();
      if (this.polygonData != null) {
        // convert the area to fit the needed format
        this.polygonData.forEach((area) => {
          area.isActive = false;
          let temporaryPolygon = null;
          let uniqueColor = this.colors[area.id] ? this.colors[area.id] : 'gray';
          if (area.positions[0].latitude != null) {
            let convertedArray = area.positions.map(function (position) {
              return [ position.latitude, position.longitude ];
            });
            // create the default polygon and add it to the layer of editable polygons
            temporaryPolygon = L.polygon(convertedArray, { color: uniqueColor });
            area.drawn = true;
          }
          area.options = {
            color: uniqueColor
          }
          if (temporaryPolygon != null) {
            temporaryPolygon.name = area.name;
            temporaryPolygon.event = area.event;
            temporaryPolygon.command = area.command;
            temporaryPolygon.deviceKey = area.deviceKey;
            temporaryPolygon.receiver = area.receiver;
            temporaryPolygon.id = area.id;
            temporaryPolygon.bindTooltip(`${ area.name ? area.name : this.$t('eventMovieComp.newArea') } (${ area.id })`);
            temporaryPolygon.addTo(this.editableLayers);
          }
          this.layerLength++;
        });
      }
      if (this.polygonData != null) {
        // add the layer of editable polygons to the map
        this.orderLayers();
      }
      this.map.addLayer(this.editableLayers);

      // add the layer to the drawPluginOptions
      this.drawPluginOptions.edit.featureGroup = this.editableLayers;

      // creates the draw-buttons (controls)
      this.drawControl = new L.Control.Draw(this.drawPluginOptions);

      // gets called every time the draw-plugin creates a new area
      this.map.on(L.Draw.Event.CREATED, (e) => {
        let tmpPolygon = this.polygonData.find(o => Number(o.id) === Number(this.drawingId));
        let uniqueColor = this.colors[this.drawingId] ? this.colors[this.drawingId] : 'gray';
        let layer = e.layer;
        layer.name = this.nameToUse;
        // create a unique id
        layer.id = this.drawingId;
        tmpPolygon.drawn = true;
        tmpPolygon.options.color = uniqueColor;
        layer.editing.latlngs[0][0].forEach((latlng) => {
          latlng.lat = Number.parseFloat(latlng.lat).toFixed(5);
          latlng.lng = Number.parseFloat(latlng.lng).toFixed(5);
        });
        tmpPolygon.positions = layer.editing.latlngs[0][0].map((o) => {
          return { latitude: o.lat, longitude: o.lng }
        });
        layer.setStyle({ color: uniqueColor });
        layer.bindTooltip(`${ this.nameToUse } (${ this.drawingId })`);
        this.editableLayers.addLayer(layer);
        this.layerLength++;
        this.drawingPolygon = false;
        tmpPolygon.isActive = false;
        this.nameToUse = '';
        this.orderLayers();
        this.increaseIndex();
        this.unsavedChanges = true;
      });

      this.map.fitBounds(this.bounds);
      if (this.bounds._northEast.lat === 0 && this.bounds._northEast.lng === 0 && this.bounds._southWest.lat === 0 && this.bounds._southWest.lng === 0) {
        this.map.setZoom(2);
      }
    },
    getUniqueIndex () {
      let uniqueInt = 0;
      if (this.polygonData != null) {
        let temporaryIdArray = this.polygonData.map(area => {
          return area.id;
        });
        temporaryIdArray.forEach(() => {
          if (temporaryIdArray.includes(uniqueInt)) {
            uniqueInt++;
          }
        });
      }
      return uniqueInt;
    },
    increaseIndex () {
      this.mapIndex++;
    }
  }
}
</script>

<style scoped>
#eventMovieWrapper {
  width: 100%;
  min-height: 500px;
}
</style>

<style>
.eventMovie table {
  /* override Bootstrap's width */
  width: inherit !important;
}
.eventMovie td, th {
  width: auto;
}
.eventMovie td.min, th.min {
  width: 1% !important;
  white-space: nowrap !important;
}
</style>
